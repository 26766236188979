//* ***** RESET
	//@import 'reset/_reset.scss';
	@import 'reset/_generic.scss';

//* ***** POSITIONS
	@import 'position/_header.scss';
	@import 'position/_footer.scss';
	@import 'position/_layout.scss';

	@import 'position/_edit.scss';

//* ***** FUNTIONALITIES
	@import 'functionalities/_user.scss';
	@import 'functionalities/_page-403-404.scss';
	@import 'functionalities/_blog.scss';
	@import 'functionalities/_product.scss';
	@import 'functionalities/_project.scss';
	@import 'functionalities/_contact.scss';

//* ***** FUNTIONALITIES
	@import 'gadgets/_g_layout.scss';
	@import 'gadgets/_g_html.scss';
	@import 'gadgets/_g_multimedia.scss';
	@import 'gadgets/_g_slider.scss';
	@import 'gadgets/_g_icons.scss';
	@import 'gadgets/_g_item.scss';
	@import 'gadgets/_g_button.scss';
	@import 'gadgets/_g_ubication.scss';
	@import 'gadgets/_g_separator.scss';
	@import 'gadgets/_g_content.scss';
	@import 'gadgets/_g_detalle.scss';
	@import 'gadgets/_g_destacado.scss';
	@import 'gadgets/_g_title.scss';
	@import 'gadgets/_g_listado.scss';
	@import 'gadgets/_g_form.scss';
	@import 'gadgets/_g_textoimagen.scss';

