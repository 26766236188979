@import "_recipes";
@import "_variables";

//* ************** NODE - PROJECT
  .node-project {
    .field-name-field-integer-und,
    .field-name-field-country1-und {
      display: inline-block; vertical-align: middle;
      margin: 15px 0 10px;
      font-weight: bold;
    }
    .field-name-field-integer-und {
      + .field-name-field-country1-und {
        &:before {
          content: "|";
          margin: 0 10px;
        }
      }
    }
    .field-name-field-img1-lang {
    	overflow: hidden;
    	img {
    		max-width: 1902px;
				margin: 0 auto;
    	}
    }
    .field-name-field-longtext-teaser-lang {
      display: block;
      margin: 35px 0;
    }
    &.view-mode-full {
      .field-name-field-img1-lang {
        + .title-h1 {
          position: absolute; top: 0; left: 0; right: 0;
          margin: 0px 0 0;
          @include rgba-fallback(bg, $black, 0.9);
          text-align: center;
          .field-name-title {
            display: inline-block;
            padding: 20px 20px 24px; margin: 0;
            //border-top: 1px solid #716f6e;
            text-align: center; text-transform: uppercase; color: $white; font-size: 30px; line-height: 1.05em;
          }
        }
      }
      .title-h1 {
        &:first-child { @extend .container-width;}
      }
    }
    &.view-mode-teaser {
      margin: 0 auto;
      .group-bottom {
        padding: 14px;
        background-color: #474543;
        color: $white; font-size: 18px;
        a {
          margin-left: 6px;
          color: inherit; text-decoration: underline; text-transform: lowercase;
          &:hover { color: $corporativoPrincipal;}
        }
      }
    }
  }