@import "_recipes";
@import "_variables";


//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-destacado {
		display: block;
		max-width: 480px; margin: 50px 13px 0;
		.field-name-field-hiwc-covers-link {
			display: table; width: 100%;
			margin: 0; padding: 12px 0 28px;
			background-color: $corporativoPrincipal;
			height: 2.1em;
			color: $white; text-transform: uppercase; text-align: center; font-size: 30px; line-height: 1.05em; font-weight: normal;
			a {
				display: table-cell; vertical-align: middle;
				padding: 0 20px;
				color: inherit;
				@include transform(perspective(1px) translateZ(0));
				@include transition(transform 0.3s);
				&:hover { @include transform(translateX(8px));}
			}
		}
		.field-name-field-hiwc-covers-image {
			display: inline-block; width: 100%;
			img {
				position: relative;
				z-index: 9;
				margin: -20px auto 0px;
			}
		}
		.bg-bottom-grey & {
			.field-name-field-hiwc-covers-image {
				position: relative;
				&:before {
					content: '';
					position: absolute; bottom: 14px; top: 0; left: 0; right: 0;
					//z-index: 9;
					background-color: #e5e5e5;
				}
				&:after {
					content: '';
					position: absolute; bottom: 14px; left: 0; right: 0;
					//z-index: 9;
					height: 190px;
					background-color: $corporativoSecundario;
				}
			}
		}
	}

	.bg-bottom-grey {
		position: relative;
		&:before {
			content: '';
			position: absolute; bottom: 18px; left: 0; right: 0;
			//z-index: 9;
			height: 190px;
			background-color: $corporativoSecundario;
			@media all and (max-width: 639px) { display: none;}
		}
	}

	//* RESPONSIVE
		@media all and (max-width: 799px) {
			.gadget-destacado {
				.field-name-field-hiwc-covers-link { font-size: 24px;}
			}
		}