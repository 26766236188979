@import "bourbon/bourbon";


//* ***************************************** VARIABLES */
  $black: #33302e;
  $dark-blue: #235a62;
  $grey: #424242;
  $dark-red: #ba2c3b;
  $red: #cf3e23;
  $white: #FFFFFF;

  $orange: #f2af00;
  $green: #00a78b;
  $yellow: #fcc537;

  $corporativoPrincipal: #cc0000;
  $corporativoSecundario: #33302e;

  $link: $corporativoSecundario;
  $link_hover: $corporativoPrincipal;
  $text: $black;
  $titles: $black;


//* ***************************************** PAGE CONTAINER */
  $maxWidth: 1206px;

//* ***************************************** FONTS / ICONS */
  // @include font-face('opensanslight', '../fonts/opensanslight/opensanslight', $file-formats: eot woff2 woff);
  // @include font-face('latoitalic', '../fonts/latoitalic/latoitalic', $file-formats: eot woff2 woff);
  // @include font-face('latoblack', '../fonts/latoblack/latoblack', $file-formats: eot woff2 woff);
  // @include font-face('latoregular', '../fonts/latoregular/latoregular', $file-formats: eot woff2 woff);
  // @include font-face('latobold', '../fonts/latobold/latobold', $file-formats: eot woff2 woff);
  @include font-face('futuramedium', '../fonts/futuramedium/futuramedium', $file-formats: eot woff2 woff);
  @include font-face('futuracondensedmedium', '../fonts/futuracondensedmedium/futuracondensedmedium', $file-formats: eot woff2 woff);
  @include font-face('icomoon', '../fonts/icomoon/icomoon', $file-formats: tff eot woff2 woff);

  // $light: 'opensanslight', Arial, sans-serif;
  // $italic : 'latoitalic', Arial, sans-serif;
  // $black : 'latoblack', Arial, sans-serif;
  // $regular: 'latoregular', Arial, sans-serif;
  // $bold : 'latobold', Arial, sans-serif;
  $medium : 'futuramedium', Arial, sans-serif;
  $condensedmedium : 'futuracondensedmedium', Arial, sans-serif;
  $icomoon : 'icomoon', Arial, sans-serif;