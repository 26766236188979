@import "_recipes";
@import "_variables";


.gadget-listado {
	&.view-mode-full {
		margin-bottom: 120px;
		.view-display-id-field_group_embed_view_2 {
			.views-row { margin: 40px 0;}
		}
		.view-display-id-field_group_embed_view_1 {
			.view-content { font-size: 0;}
			.views-row {
				display: inline-block; width: 33.33%;
				.node { margin: 13px;}
			}
		}
	}
}

.view-mode-cuadricula_hiwc_covers {
	margin: 0 auto;
	.field-name-title {
		margin-bottom: 12px;
		font-size: 22px; text-align: center;
	}
  .group-bottom {
    padding: 14px;
    background-color: #474543;
    color: $white; font-size: 18px;
    a {
      margin-left: 6px;
      color: inherit; text-decoration: underline; text-transform: lowercase;
      &:hover { color: $corporativoPrincipal;}
    }
  }
  .field-name-field-integer-und, .field-name-field-country1-und { margin-top: 0; margin-bottom: 0;}
  .field-name-field-longtext1-teaser-lang { font-size: 16px;}
}

.view-mode-listado_hiwc_covers {
	margin: 0 auto;
	.group-left {
		width: 32%;
		+ .group-right {
			width: 68%;
			> * { margin: 14px;}
		}
	}
	.field-name-field-img1-lang {
		img { margin-right: 20px;}
	}
	.field-name-title { margin-bottom: 14px;}
  .group-bottom {
    font-size: 18px;
    a {
      margin-left: 6px;
      color: inherit; text-decoration: underline; text-transform: lowercase;
      &:hover { color: $corporativoPrincipal;}
    }
  }
  .field-name-field-longtext1-teaser-lang { font-size: 16px;}
}
