@import "_recipes";
@import "_variables";


//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-ubication {
		&.view-mode-full { display: block;}
		margin: 20px 0;
		//&.view-mode-full {}
	}
