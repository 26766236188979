@import "_recipes";
@import "_variables";


//* *********** REGION FOOTER
	#footer {
		position: relative;
		body:not(.node-type-cover) & { margin-top: 120px;}
	}
	//* ***** MAIN MENU
		.region-footer {
			div[id*='block-menu-block'] {
				padding: 40px 0;
				background-color: $black;
				color: $white; font-size: 14px;
				a { color: inherit;}
				.menu-block-wrapper {
					@extend .container-width;
					.menu { margin: 0; padding: 0;}
					li { margin: 0; padding: 0;}
					> .menu {
						display: table; width: 100%;
						> li {
							display: table-cell; vertical-align: top;
							text-align: center;
							a {
								display: block;
								padding: 3px 10px;
							}
							> a {
								margin-bottom: 8px;
								font-size: 1.1em;
							}
						}
						.menu { text-align: center;}
					}
				}
			}
		}
	//* ***** BASE MENU
		#block-menu-menu-menu-base {
			padding: 19px 0 18px;
			.content { @extend .container-width;}
			.menu {
				margin: 0; padding: 0;
				text-align: center;
				li {
					display: inline-block; vertical-align: middle;
					margin: 0; padding: 0;
					+ li {
						&:before {
							content: "|";
							color: $corporativoPrincipal;
						}
					}
					a {
						//display: inline-block;
						padding: 2px 22px;
					}
				}
			}
		}
	//* ***** CORPORATE BLOCK
		.region-footer {
			.block-block {
				padding: 28px 14px 40px;
				@include rgba-fallback(bg, $black, 0.1);
			}
		}
		#block-block-1 {
			text-align: center; line-height: 1.428em;
			p { margin: 0;}
		}


//* *************** RESPONSVIE ***** */
	@media all and (max-width: 1023px) {
		//* *********** REGION FOOTER
			//* ***** MAIN MENU
				.region-footer {
					div[id*='block-menu-block'] {
						.menu-block-wrapper {
							> .menu {
								display: block;
								font-size: 0;
								> li {
									display: inline-block;
									width: 25%;
									font-size: 13px;
									&:nth-child(n+5) { margin-top: 24px;}
									> .menu { padding-left: 10px; padding-right: 10px;}
								}
							}
						}
					}
				}
	}
	@media all and (max-width: 767px) {
		//* *********** REGION FOOTER
			//* ***** MAIN MENU
				.region-footer {
					div[id*='block-menu-block'] {
						.menu-block-wrapper {
							> .menu {
								> li {
									width: 33.33%;
									&:nth-child(n+4) { margin-top: 24px;}
								}
							}
						}
					}
				}
	}
	@media all and (max-width: 599px) {
		//* *********** REGION FOOTER
			//* ***** MAIN MENU
				.region-footer {
					div[id*='block-menu-block'] {
						.menu-block-wrapper {
							> .menu {
								> li {
									width: 50%;
									&:nth-child(n+3) { margin-top: 24px;}
								}
							}
						}
					}
				}
		//* ***** BASE MENU
			#block-menu-menu-menu-base .menu li a {
			  padding: 2px 8px;
			  font-size: 13px;
			}
		//* ***** COPY
			#block-menu-menu-menu-base { padding: 12px 0 12px;}
			#pmesa-corporate { font-size: 13px;}
	}
	@media all and (max-width: 459px) {
		//* *********** REGION FOOTER
			//* ***** MAIN MENU
				.region-footer {
					div[id*='block-menu-block'] {
						.menu-block-wrapper {
							> .menu {
								> li {
									width: 100%;
									&:nth-child(n+2) { margin-top: 24px;}
								}
							}
						}
					}
				}
	}