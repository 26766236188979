@import "_recipes";
@import "_variables";



.gadget-texto-imagen {
  margin: 20px 0; }
  .gadget-texto-imagen .group-left {
    width: calc(50% - 20px);
    margin-right: 20px; }
  .gadget-texto-imagen .group-right {
    width: calc(50% - 20px);
    margin-left: 20px; }
  .gadget-texto-imagen.view-mode-left_image .group-right .field-name-field-text1-und {
    font-size: 26px;
    margin-bottom: 10px; }
  .gadget-texto-imagen.view-mode-right_image .group-left .field-name-field-text1-und {
    font-size: 26px;
    margin-bottom: 10px; }

@media all and (max-width: 768px) {
  .gadget-texto-imagen {
    max-width: 100%;
    margin: 30px auto;
    padding: 0 10px; }
    .gadget-texto-imagen .group-left {
      width: 100%;
      margin-right: 0px;
      float: inherit;
      display: block; }
    .gadget-texto-imagen .group-right {
      width: 100%;
      margin-left: 0px;
      float: inherit;
      display: block; }
      .gadget-texto-imagen .group-right .field-name-field-text1-und {
        margin-top: 20px; }
    .gadget-texto-imagen.view-mode-right_image .group-left {
      display: table-footer-group; }
      .gadget-texto-imagen.view-mode-right_image .group-left .field-name-field-text1-und {
        margin-top: 20px; }
    .gadget-texto-imagen.view-mode-right_image .group-right {
      display: table-header-group; } }