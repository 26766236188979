@import "_recipes";
@import "_variables";

#wrapper.error-container {
  box-sizing: border-box;
  padding:0 0px;
  background: #ffffff;
  body:not(.page-node-edit) & { text-align: center; color: $corporativoPrincipal;}
  #branding {
    display: block;
    padding: 0px 0; margin: 0;
    img { max-width: 100%;}
  }
  #error-name {
    display: block;
    margin: 70px auto 50px;
    font-size: 205px; line-height: 205px; text-align: center;
  }
  #error-explain { font-size: 45px; line-height: 1.05em;}
  #back-button {
    display: block;
    width:30%;
    margin: 30px auto 20px auto; padding:15px 30px;
    background: $corporativoSecundario;
    @include appearance(none);
    //@include box-shadow(0 2px 2px #868686);
    @include border-top-radius(4px);
    @include border-bottom-radius(4px);
    color: #ffffff; text-decoration: none; font-size: 22px; line-height: 1.3em;
    @include transition(all 0.2s ease-in-out);
    &:hover {
      padding:15px 60px;
      //@include box-shadow(0 0 0 #868686);
    }
  }
  .alternative {
    line-height:1.3em;
    a { color: $corporativoSecundario; font-family: $medium;}
  }
  #footer {
    margin: 70px 0 0;
    background-color: $corporativoSecundario;
    text-align: center;
    #img-not-found {
      img {
        display: inline-block;
        max-width: 100%;
        margin: -70px auto 0;
      }
    }
  }
}


