@import "_recipes";
@import "_variables";

//* *************** USER ***** */
  .page-user {
    .region-content {
      @extend .container-width;
      .content { padding: 0 15px;}
    }
  }

  #user-login,
  #user-pass {
    margin: 0 auto;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid $corporativoPrincipal;
    background-color: $white;
    //@include box-shadow(2px 2px 5px #999999);
    > div {
      max-width: 100%;
      margin: 30px;
    }        
    .compact-form-label {
      padding: 11px 5px 10px 55px;
      font-size: 16px;
    }
    input[type="text"],
    input[type="password"]  {
      padding: 11px 5px 10px 55px;
      background-position: left center;
      background-repeat: no-repeat;
    }
    .form-item-name  input[type="text"] {
      background-image: url(../images/form/ico-form-name.png);
      &:focus { background-image: url(../images/form/ico-form-name-focus.png);}
    }
    .form-item-pass input[type="password"] {
      background-image: url(../images/form/ico-form-pass.png);
      &:focus { background-image: url(../images/form/ico-form-pass-focus.png);}
    }

    /* Captcha */
      .form-item-captcha-response {
        .compact-form-label {
          padding: 30px 10px 15px 10px;
          font-size: 20px;
        }
      }
    
    .form-actions {
      text-align:center;
      margin-top: 25px;
      input[type="submit"] {
        //border: 1px solid #ba2c3b;
        font-family: $medium; font-size: 20px; text-transform: none;
        //&:hover {
        //  background-color: #FFF;
        //  color: $corporativoPrincipal;
        //}
      }
    }
  }
  #user-login { max-width: 500px;}
  #user-pass { max-width: 700px;}

  /* ********** EDIT FORM ***** */
    .page-user-edit {
      .form-item {
        > label, > input { display: inline-block; vertical-align: middle;}
        > label { 
          width: 17%;
          margin: 0 2% 0 0;
          text-align: right;
          & + input { width: 80%;}
        } 
        > .description { margin-left: 19%;}
        &.form-type-checkboxes,
        &.form-type-radios {
          > * { 
            display: inline-block; vertical-align: top;}
          > label { 
            margin-top: 8px;
            & + .form-radios { width: 80%;}
          }
          .form-type-checkbox,
          .form-type-radio {            
            label { 
              width: auto; 
              margin: 0; 
              text-align: left;
            }
          } 
        }     
      }
      .form-type-password-confirm {
        .form-type-password {
          @include clearfix;
          width: 100%;
          > label { 
            float: left;
            width: 17%;
            margin: 54px 2% 0 0;
            text-align: right;
            & + input { float: right; width: 80%;}
          } 
          & + .password-suggestions { margin-left: 20%; width: auto;}
        }
      }

      .group-wrapper-password {
        legend {
          display: block; 
          background-color: #f4f8fc;
          width: 100%;
          .fieldset-legend { padding: 18px;}
          span.summary { display: none;} 
        } 
        .fieldset-wrapper {
          padding: 18px;
          background-color: #f4f8fc;
        }      
        html.js & { 
          &.collapsed { height: auto;}
          .fieldset-legend { 
            padding: 18px;
            background-image: none;
          }
        }
      }
    }