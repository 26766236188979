@import "_recipes";
@import "_variables";


.gadget-texto {
	&.view-mode-full {
		.field-name-field-hiwc-covers-link {
			a {
				position: absolute; top: 0; left: 0; right: 0; bottom: 0;
				font-size: 0;
			}
		}
	}
} 