@import "_recipes";
@import "_variables";

//* ************** NODE - PRODUCT
  .node-product {
    &.view-mode-full {
      .group-header {
        position: relative;
        margin-bottom: 40px;
        height: 430px;
        text-align: center;
        .group-product {
          position: absolute; top: 0; left: 0; right: 0;
          .title-wrapper {
            padding: 40px 0 0;
            @include rgba-fallback(bg, $black, 0.9);
            @include transition(padding-top 0.3s ease-in-out 0s);
          }
        }
        .field-name-title {
          position: relative;
          display: inline-block;
          margin: 80px 0 0; //padding: 20px 20px 24px;
          border-top: 1px solid #716f6e;
          @include transition(margin-top 0.3s ease-in-out 0s);
          //text-align: center; text-transform: uppercase; color: $white; font-size: 30px; line-height: 1.05em;
          &:before {
            position: absolute; top: -80px; left: 0; right: 0;
            @include icomoon(72px);
            color: $corporativoPrincipal;
            @include transition(all 0.3s ease-in-out 0s);
          }
        }
        .view--product-imagen-taxonomia-padre {
        	overflow: hidden;
        	img {
        		max-width: 1902px;
						margin: 0 auto;
            @include transition(margin-top 0.3s ease-in-out 0s);
        	}
        }
        .field-name-field-img2-lang {
          img {
            display: inline-block;
            margin-top: -100px;
          }
        }
        .scrolled & {
          .group-product .title-wrapper { padding-top: 15px;}
          .view--product-imagen-taxonomia-padre img { margin-top: -55px;}
          .field-name-title { 
            margin-top: 50px;
            &:before {
              top: -55px;
              font-size: 38px;
            }
          }
        }
      }
      .group-top {
        font-size: 0;
        > div {
          display: inline-block; vertical-align: top;
          margin-top: 40px;
          width: 50%;
          font-size: 17px;
          > div { padding: 0 80px;}
        }
        // .field-name-field-longtext2-lang {
        //  	margin-right: -1px;
        //  	border-right: 1px solid $corporativoPrincipal;
        // }
        .field-name-field-longtext3-lang {
         	margin-left: -1px;
         	border-left: 1px solid $corporativoSecundario;
        }
        .field-label {
          margin-bottom: 40px;
          font-weight: normal;
          font-size: 30px; color: $corporativoPrincipal; text-transform: uppercase; text-align: center;
        }
      }
      .group-construction {
        margin-top: 30px; margin-bottom: 25px;
        background-image: url(../images/bg-construction.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow-x: hidden;
        .field-name-field-longtext1-lang {
          @extend .container-width;
          position: relative;
          padding: 0;
          .field-label,
          .field-item {
            margin-left: 300px;
            padding-left: 80px; padding-right: 20px;
            @include rgba-fallback(bg, $black, 0.9);
            color: $white;
          }
          .field-label {
            margin-top: 0; margin-bottom: 0; padding-top: 50px; padding-bottom: 30px;
            text-transform: uppercase; font-size: 30px;
          }
          .field-item {
            padding-bottom: 40px;
            p { margin-top: 0; margin-bottom: 0; padding-top: 10px;}
          }
          &:after {
            content: "";
            position: absolute; left: 100%; top: 0; bottom: 0;
            width: 400px;
            @include rgba-fallback(bg, $black, 0.9);
          }
        }
      }
      .field-name-field-longtext4-lang {
        margin-top: 75px;
        .label-above {
          margin-bottom: 35px;
          font-weight: normal;
          font-size: 30px; text-transform: uppercase; text-align: center; line-height: 1.05em;
        }
        sup { font-size: 0.5em;}
        table {
          &.col6 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 40px;}
            td:nth-child(4) { width: 60px;}
            td:nth-child(5) { width: 90px;}
            td:nth-child(6) { width: 90px;}
            td:nth-child(7) { width: 90px;}
          }
          &.col7 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 40px;}
            td:nth-child(4) { width: 60px;}
            td:nth-child(5) { width: 90px;}
            td:nth-child(6) { width: 90px;}
            td:nth-child(7) { width: 90px;}
            td:nth-child(8) { width: 90px;}
          }
          &.col8 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 40px;}
            td:nth-child(4) { width: 60px;}
            td:nth-child(5) { width: 70px;}
            td:nth-child(6) { width: 90px;}
            td:nth-child(7) { width: 90px;}
            td:nth-child(8) { width: 90px;}
            td:nth-child(9) { width: 90px;}
          }
          &.col9 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 60px;}
            td:nth-child(4) { width: 90px;}
            td:nth-child(5) { width: 90px;}
            td:nth-child(6) { width: 50px;}
            td:nth-child(7) { width: 40px;}
            td:nth-child(8) { width: 40px;}
            td:nth-child(9) { width: 40px;}
            td:nth-child(10) { width: 40px;}
          }
          &.col10 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 60px;}
            td:nth-child(4) { width: 60px;}
            td:nth-child(5) { width: 90px;}
            td:nth-child(6) { width: 90px;}
            td:nth-child(7) { width: 90px;}
            td:nth-child(8) { width: 40px;}
            td:nth-child(9) { width: 40px;}
            td:nth-child(10) { width: 70px;}
            td:nth-child(11) { width: 70px;}
          }
          &.col11 {
            td:nth-child(2) { width: 70px;}
            td:nth-child(3) { width: 60px;}
            td:nth-child(4) { width: 60px;}
            td:nth-child(5) { width: 90px;}
            td:nth-child(6) { width: 90px;}
            td:nth-child(7) { width: 90px;}
            td:nth-child(8) { width: 40px;}
            td:nth-child(9) { width: 40px;}
            td:nth-child(10) { width: 40px;}
            td:nth-child(11) { width: 70px;}
            td:nth-child(12) { width: 70px;}
          }
        }
      }
      &.tid-14 {
      // &.product-type-mangueras-de-incendios,
      // &.product-type-fire-hoses {
        .group-header .field-name-title:before {content: "\e900";}
      }
      &.tid-15 {
      // &.product-type-mangueras-industriales,
      // &.product-type-industrial-hose {
        .group-header .field-name-title:before {content: "\e903";}
      }
      &.tid-22 {
      // &.product-type-mangueras-agricolas,
      // &.product-type-agricultural-hoses {
        .group-header .field-name-title:before {content: "\e901";}
      }
      &.tid-23 {
      // &.product-type-mangueras-a-medida,
      // &.product-type-custom-made-hoses {
        .group-header .field-name-title:before {
          content: "\e910"; //content: "\e902";
        }
      }
      .group-footer { position: relative;}
    }
    &.view-mode-token {
      position: relative;
      z-index: 9;
      max-width: 578px; margin: 0 auto;
      padding-top: 38px;
      text-align: center;
      .field-name-title {
        position: absolute; top: 0; left: 0; right: 0;
        z-index: 9;
        margin: 0 0 13px;
        color: #474543; font-size: 24px; text-transform: uppercase; line-height: 1.05em;
        a { color: inherit;}
      }
      // .group-image {
      //   position: relative;
      //   .view--product-imagen-taxonomia-padre {
      //     .views-row {
      //       width: auto;
      //       margin: 0;
      //     }
      //     + .field-name-field-img1-lang {
      //       position: absolute; left: 10%; right: 10%; bottom: 0;
      //       height: 90%;
      //     }
      //   }
      // }
      .field-name-field-img1-lang {
        overflow: hidden;
        img {
          @include transform(scale(1))
          @include transition(all .5s ease-in-out 0s);
        }
      }
      .field-name-body {
        position: relative;
        z-index: 9;
        padding: 14px;
        background-color: #474543;
        .field-item {
	        height: 2.19em; overflow: hidden;
	        color: $white; font-size: 18px; line-height: 1.1em;
          @include transition(margin 0.2s ease-in-out 0s);
	      }
      }
      &:before {
        content: '';
        position: absolute; left: 0; right: 0; bottom: 0;
        z-index: 9;
        height: 0;
        background-color: $white;
        @include transition(height 0.2s ease-in-out 0s);
      } 
      &:hover {
        // &:before { height: 100%;}
        // .field-name-title a {
        //   display: inline-block;
        //   padding-bottom: 8px; padding-top: 24%;
        //   border-bottom: 1px solid $corporativoPrincipal;
        // }
        // .field-name-body { 
        //   background-color: $white;
        //   .field-item {
        //     margin-top: -21%; margin-bottom: 21%;
        //     color: $corporativoSecundario;
        //   }
        // }
        .field-name-field-img1-lang {
          img { @include transform(scale(1.03));}
        }
      }
    }
    &.view-mode-teaser {
      max-width: 295px;
      margin-left: auto; margin-right: auto; padding-left: 10px; padding-right: 10px;
      text-align: center; font-size: 18px; line-height: 1.1em;
      img { display: inline-block;}
      .field-name-title {
        padding-bottom: 10px; margin-bottom: 18px; margin-top: 0;
        border-bottom: 1px solid $text;
        font-size: 22px; text-transform: uppercase; line-height: 1.1em;
        display: table; 
        width: 100%; height: 2.2em;
        a { display: table-cell; vertical-align: bottom;}
      }
      .field-name-field-img2-lang {
        overflow: hidden;
        img {
          @include transform(scale(1))
          @include transition(all .5s ease-in-out 0s);
        }
      }
      &:hover {
        .field-name-title {
          a { color: $corporativoPrincipal;}
        }
        .field-name-field-img2-lang {
          img { @include transform(scale(1.05));}
        }
      }
      //.field-name-body {}
    }
  }

  #prod-quality {
  	margin: 32px 0;
  	text-align: center;
  	img {
  		display: inline-block; vertical-align: middle;
  		margin: 14px 14px;
  		max-height: 70px;
  	}
  }
  #quality-test {
    position: relative;
  	display: inline-block; vertical-align: top;
    padding-bottom: 67px; margin-top: 20px; margin-bottom: 20px;
    width: 49%;
    .field-content {
    	position: absolute; top: 0; left: 0; right: 0; bottom: 0;
    	max-width: 370px; margin-left: auto; margin-right: auto;
    	text-align: center;
    	.field-items {
    		display: table;
    		width: 100%; height: 100%;
    		> .field-item { display: table-cell; vertical-align: middle;}
    	}
    }
    .claim {
    	padding: 0 6px 10px; margin: 0;
    	font-size: 44px; line-height: 1.2em; font-weight: normal;
    	span { color: $corporativoPrincipal;}
    }
    .description {
    	display: inline-block;
    	padding: 12px 2px 10px;
    	border-top: 1px solid $corporativoPrincipal;
    	font-size: 27px;
    }
  }

//* ************** TAXONOMY - PRODUCT TYPE
  .vocabulary-product-type {
    &.view-mode-full {
    	// border-bottom: 1px solid #c1c1c0;
    	// margin-bottom: 80px;
      .group-header {
        padding: 40px 0 0;
        background-color: $black;
        text-align: center;
        .field-name-name-field {
          position: relative;
          display: inline-block;
          margin: 80px 0 0; //padding: 20px 20px 24px;
          border-top: 1px solid #716f6e;
          //text-transform: uppercase; color: $white; font-size: 30px; line-height: 1.05em;
          &:before {
            position: absolute; top: -80px; left: 0; right: 0;
            @include icomoon(72px);
            color: $corporativoPrincipal;
            @include transition(padding-top 0.3s ease-in-out 0s);
          }
        }
        .scrolled & {
          padding-top: 15px;
          @include transition(padding-top 0.3s ease-in-out 0s);
          .field-name-name-field {
            margin-top: 50px;
            @include transition(padding-top 0.3s ease-in-out 0s);
            &:before { 
              top: -55px;
              font-size: 38px;
            }
          }
        }
      }
      .field-name-prod-quality {
        &:after {
          content: '';
          display: block;
          margin-left: -50%; margin-right: -50%;
          margin-bottom: 80px;
          width: 200%; height: 1px;
          background-color: #c1c1c0;;
        }
      }
      &.tid-14 {
      // &.product-type-mangueras-de-incendios,
      // &.product-type-fire-hoses {
        .group-header .field-name-name-field:before {content: "\e900";}
      }
      &.tid-15 {
      // &.product-type-mangueras-industriales,
      // &.product-type-industrial-hose {
        .group-header .field-name-name-field:before {content: "\e903";}
      }
      &.tid-22 {
      // &.product-type-mangueras-agricolas,
      // &.product-type-agricultural-hoses {
        .group-header .field-name-name-field:before {content: "\e901";}
      }
      &.tid-23 {
      // &.product-type-mangueras-a-medida,
      // &.product-type-custom-made-hoses {
        .group-header .field-name-name-field:before {
          content: "\e910"; 
          //content: "\e902";
        }
      }
    }
    &.view-mode-token {
      max-width: 578px; margin: 0 auto;
      text-align: center;
      .field-name-name-field {
        margin: 0 0 8px; //margin: 0 0 13px;
        color: #474543; font-size: 24px; text-transform: uppercase; line-height: 1.05em;
        display: table;
        width: 100%; height: 2.1em; 
        a { 
          display: table-cell; vertical-align: middle;
          color: inherit;
        }
      }
      .field-name-field-img1-lang {
        overflow: hidden;
        img {
          @include transform(scale(1))
          @include transition(all .5s ease-in-out 0s);
        }
      }
      &:hover {
        .field-name-field-img1-lang {
          img { @include transform(scale(1.03));}
        }
      }
      .field-name-description-field {
      	padding: 14px;
        background-color: #474543;
        .field-item {
	        height: 2.19em; overflow: hidden;
	        color: $white; font-size: 18px; line-height: 1.1em;
	      }
      }
    }
  }

//* ************** VIEW
  //* ********* PRODUCT TYPE
    .view-prod-type {
      &.view-display-id-block {
        .view-content { 
          font-size: 0;
          > .views-row {
            display: inline-block; vertical-align: top;
            margin-top: 20px; margin-bottom: 20px;
            width: 49%;
          }
        }
        [class*='even'] { margin-left: 1%;}
        [class*='odd'] { margin-right: 1%;}
        .page-taxonomy-term & { @extend .container-width;}
      }
    }
  //* ********* RELATED PRODUCTs
    .field-name-field-entityref1-lang-multi {
      margin-top: 70px;
      text-align: center;
      .node-product-form & { margin-top: 0;}
      > .field-label {
        margin: 0;
        text-transform: uppercase; font-size: 30px; text-align: center;
      }
      .field-items { font-size: 0;}
      .field-item {
        display: inline-block; vertical-align: top;
        margin-top: 20px; margin-bottom: 20px;
        width: 25%;
      }
    }

.node-type-product,
.page-taxonomy-term {
  h1 {
    padding: 20px 20px 24px;
    text-align: center; text-transform: uppercase; color: $white; font-size: 30px; line-height: 1.05em;
    &.fixed-title {
      display: none;
      position: absolute; top: 100%; left: 0; right: 0;
      margin: 0;  
    } 
  }
}
.node-type-product {
  h1.fixed-title { @include rgba-fallback(bg, $corporativoSecundario, 0.9);}
}

.page-taxonomy-term {
  h1.fixed-title { @include rgba-fallback(bg, $corporativoSecundario, 1);}
}

//* ************** RESPONSIVE
	@media all and (max-width: 1023px) {
		.node-product {
			&.view-mode-teaser {
				font-size: 14px;
				.field-name-title { font-size: 20px;}
			}
		}
	}
	@media all and (max-width: 899px) {
		.node-product {
			&.view-mode-full {
				.group-top > div > div { padding-left: 20px; padding-right: 20px;}
			}
		} 
    .vocabulary-product-type.view-mode-token .field-name-description-field .field-item,
    .node-product.view-mode-token .field-name-body .field-item {
      font-size: 16px;
    }
	}
	@media all and (min-width: 800px) and (max-width: 899px) {
		.node-product.view-mode-token .field-name-title,
    .vocabulary-product-type.view-mode-token .field-name-name-field { font-size: 20px;}
	}
	@media all and (max-width: 799px) {
		//* ***** SECTION TITLE
			.vocabulary-product-type.view-mode-full {
				.group-header {
					padding-top: 10px;
						.field-name-name-field {
						padding: 14px 14px 18px; margin-top: 65px;
						font-size: 26px;
						&:before {
							top: -65px;
							font-size: 54px;
						}
					}
				}
			}
			.node-product.view-mode-full {
				.group-header {
					.group-product .title-wrapper { padding-top: 10px;}
					.field-name-title {
						padding: 14px 14px 18px; margin-top: 65px;
						font-size: 26px;
						&:before {
							top: -65px;
							font-size: 54px;
						}
					}
				}
			}

		.view-prod-type {
	    &.view-display-id-block {
	      .view-content {
          > .views-row { width: 100%;}
          > [class*='even'] { margin-left: 0%;}
          > [class*='odd'] { margin-right: 0%;}
        } 
	    }
	  }
	  .node-product {
	  	.field-name-field-entityref1-lang-multi {
	  		.field-item { width: 50%;}
	  	}
	  }
	  #quality-test { width: 100%;}
	}
	@media all and (max-width: 767px) {
		.node-product {
			&.view-mode-full {
				.group-top > div { width: 100%;}
				.group-top {
					.field-label {
						margin-bottom: 20px;
						text-align: left;
					}
	        .field-name-field-longtext3-lang {
	         	margin-left: 0px; margin-top: 50px;
	         	border-left: 0 none;
	        }
				}
				.group-construction .field-name-field-longtext1-lang {
					.field-label,
					.field-item { padding-left: 30px; margin-left: 200px;}
					.field-label {
						padding-top: 40px; padding-bottom: 20px;
						font-size: 24px;
					}
				}
			}
		}
	}
	@media all and (max-width: 639px) {
		.node-product {
			&.view-mode-full {
				.group-construction .field-name-field-longtext1-lang {
					.field-label,
					.field-item { margin-left: 100px;}
				}
			}
      &.view-mode-teaser {
        .field-name-title {
          display: block;
          width: 100%; height: auto;
          a { display: block;}
        }
      } 
	  	.field-name-field-entityref1-lang-multi {
	  		.field-item { width: 100%;}
	  	}
		}
	}
	@media all and (max-width: 479px) {
		.node-product {
			&.view-mode-full {
				.group-construction .field-name-field-longtext1-lang {
					.field-label,
					.field-item { margin-left: 0px;}
				}
			}
			&.view-mode-token {
				.field-name-title { font-size: 20px;}
				.field-name-body { font-size: 14px;}
			}
		}
		#quality-text {
			.claim { font-size: 40px;}
			.description { font-size: 25px;}
		}
	}