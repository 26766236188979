@import "_recipes";
@import "_variables";


#header {
	position: fixed; top: 0; left: 0; right: 0;
	z-index: 99;
	background-color: $white;
	.domain-mesa-corporativa & { @include rgba-fallback(bg, $corporativoSecundario, 0.85);}
	//& + #center { padding-top: 165px;}
}
#container { padding-top: 165px;}
//* ************** HEADER TOP ***** */
	#header-top {
		@extend .container-width;
		display: table;
		> div { display: table-cell; vertical-align: top;}
		.region-header-top { 
			width: 310px; //width: 275px;
			.domain-mesa-corporativa & { width: 420px;}
		}
		//#logo-floater { width: 180px;}
	}
	//* ***** LOGO
		#branding {
			//display: table; width: 100%;
			margin: 0;
			font-size: 26px; color: $corporativoPrincipal; font-weight: normal; text-transform: uppercase; line-height: 1.05em;
			> a {
				//display: table-cell; vertical-align: top;
				display: inline-block; vertical-align: middle;
				overflow: hidden;
				width: 135px; height: 233px;
				padding-left: 35px; padding-right: 10px;
				margin-bottom: -117px; //img { margin-bottom: -117px;}
				@include transition(all 0.5s ease-in-out 0s);
				.scrolled & {
					height: 166px;
					margin-bottom: -50px;
				}
			}
			.site-slogan {
				//display: table-cell; vertical-align: middle;
				display: inline-block; vertical-align: middle;
				width: calc(100% - 190px);
				padding-top: 15px;
				text-align: center;
			}
			//a { color: inherit;}
		}

	//* ***** HEADER TOP RIGHT
		.region-header-top {
			padding: 15px 0;
			text-align: right;
			> div { display: inline-block; vertical-align: middle;}
		}
		//* ***** TOP MENU
			#block-menu-menu-menu-top {
				.menu { margin: 0; padding: 0;}
				li {
					display: inline-block;
					margin: 0 18px 0 0; padding: 0;
					min-width: 75px;
				}
				a {
					display: block;
					padding: 6px 9px 4px;
					@include rgba-fallback(bg, $black, 0.1);
					text-align: center;
					.domain-mesa-corporativa & { color: $white;}
					&:hover { color: inherit;}
					.domain-mesa-corporativa & { @include rgba-fallback(bg, $corporativoPrincipal, 1);}
					.domain-mesa-corporativa & { &:hover { @include rgba-fallback(bg, $corporativoPrincipal, 0.85);}}
				}
				.first {
					.domain-mesa-corporativa & {
						a {
							border: 1px solid $white;
							background-color: transparent;
							color: $white;
							&:hover { @include rgba-fallback(bg, $white, 0.2);}
						}
					}
				}
			}
		//* ***** LOCALE MENU
			.block-locale {
				position: relative;
				width: 40px;
				padding: 5px 4px 3px;
				border: 1px solid $black;
				min-height: 16px;
				.locale-active {
					text-transform: uppercase;
					.locale-menu {
						display: inline-block; vertical-align: middle;
						margin-right: 4px; margin-top: -4px;
						border-top: 8px solid $black;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
					}
				}
				ul {
					display: none;
					position: absolute; top: 100%; left: -1px; right: -1px;
					margin: 0; padding: 0;
					border-left: 1px solid $black; border-right: 1px solid $black; border-bottom: 1px solid $black;
				}
				li {
					display: block;
					margin: 0; padding: 0;
					a,
					span {
						display: block;
						padding: 5px 4px 3px;
						text-align: right;
						background-color: white;
						&:after {
							content: "";
							padding: 0 0em;
						}
					}
				}
			}

//* ***** HEADER MENU
	#header-menu {
		background-color: $black;
		// color: $white;
		// a { color: inherit;}
	}
	//* ***** MAIN MENU
		div[id*='main-menu'] {
			@extend .container-width;
			font-size: 14px; line-height: 1.15em;
			.content {
				margin-left: 200px;
				@media all and (max-width: 999px) { margin-left: 150px;}
				> .menu {
					display: table; width: 100%;
					margin: 0; padding: 0;
					> li {
						position: relative;//*************************
						display: table-cell; vertical-align: middle;
						padding: 0; margin: 0;
						height: 50px;
						> a { text-align: center;}
						&:hover {
							.no-touchevents & {
								position: relative;
							 	> .menu { display: block;}
							}
						}
					}
					li {
						&.active-trail, &:hover {
							background-color: $white;
							@include transition(background-color 0.2s ease-in-out 0s);
							> a {
								//background-color: $white;
								color: $corporativoPrincipal;
							}
						}
						&:hover {
							.no-touchevents & {
								color: $text;
								background-color: $white;
							}
						}
					}
					.menu {
						display: none;
						position: absolute; top: 100%; left: 0;
						margin: 0; padding: 0;
						width: 200px;
						background-color: $black;
						li { margin: 0; padding: 0;}
						a { padding: 15px 14px;}
					}
				}
			}
			a {
				display: block;
				padding: 0px 14px;
				//background-color: $black;
				text-transform: uppercase; color: $white;
				&:hover {
					.no-touchevents & {
						//background-color: $white;
						color: $text;
					}
				}
				&.active-trail {
					//background-color: $white;
					color: $corporativoPrincipal;
				}
			}
			.touchevents & {
				li.expanded {
					position: relative;
					> a { padding-right: 35px;}
				}
			}
		}

		.ico-menu, .ico-submenu {
			display: none;
			z-index: 99;
			font-size: 0; color: $white; text-align: center;
			&:before { @include icomoon(20px);}
		}
		.ico-menu {
			position: absolute; top: -35px; right: 10px;
			//background-color: green;
			line-height: 22px;
			&:before { content: "\e90c";}
			@media all and (max-width: 1023px) { display: inline-block;}
		}
		.ico-submenu {
			position: absolute; top: 0px; bottom: 0; right: 0px;
			//z-index: 9;
			height: 20px;
			&:hover, &:focus { color: $corporativoPrincipal;}
			@media all and (max-width: 1023px) {
				.touchevents & {
					top: 10px; right: 8px; bottom: auto;
					display: inline-block;
				}
			}
			&:before {
				content: "\e907";
				display: block;
				//padding: 15px 6px 15px 0;
			}
			.active-trail & {
				background-color: $white;
				&:before {
					color: $corporativoPrincipal;
				}
			}
		}


//* ************** RESPONSIVE
	@media all and (max-width: 1279px) {
		div[id*='main-menu'] {
			a { padding-left: 5px; padding-right: 5px;}
		}
	}
	@media all and (max-width: 1023px) {
		//* HEADER
			// #header + #center { padding-top: 133px;}
			#container { padding-top: 133px;}
		//* HEADER TOP
			#header-top {
				position: relative;
				z-index: 99;
			}
		//* BRANDING
			#branding {
				font-size: 22px;
				> a {
				  margin-bottom: -90px;
				  width: 76px; height: 178px;
				  .scrolled & {
				  	height: 127px;
				  	margin-bottom: -39px;
				  }
				}
			}
		//* HEADER MENU
			.region-header {
				position: relative;
				height: 47px;
			}
			//* ***** MAIN MENU
				div[id*='main-menu'] {
					position: absolute; top: 100%; right: 0; //left: 0;
				  margin-right: 0 !important; padding-right: 0 !important;
				  width: auto !important;
				  background-color: $corporativoSecundario;
					.content {
						margin-left: 0;
						> .menu {
							display: none;
							> li {
								display: block;
								height: auto;
								> a {
									padding-top: 14px; padding-bottom: 14px;
									text-align: right;
								}
								.menu {
									position: relative; top: auto; left: auto; right: auto;
									width: auto;
									text-align: right;
								}
							}
						}
					}
					.menu {
						a {
							padding-left: 160px; padding-right: 35px;
						}
						.menu a {
							padding-top: 4px; padding-bottom: 4px;
							background-color: $white;
							color: $text; font-size: 0.9em;
						}
					}
				}
	}

	@media all and (max-width: 899px) {
	}

	@media all and (max-width: 799px) {
		//* HEADER TOP
			//* HEADER
				// #header + #center { padding-top: 130px;}
				#container { padding-top: 130px;}
			//* ***** LOGO
				#branding {
					> a {
						width: 72px; height: 168px;
						margin-bottom: -85px;
						.scrolled & {
							height: 130px;
							margin-bottom: -46px;
						}
					}
					.site-slogan {
						text-align: left;
					}
				}
			//* ***** REGION HEADER TOP
				.region-header-top {
					padding-top: 10px; padding-bottom: 10px;
					width: 190px;
				}
				#block-menu-menu-menu-top {
					//li.last { margin-right: 0;}
					li { margin-right: 9px;}
				}
	}

	@media all and (max-width: 767px) {
	  #header-top {
	  	.region-header-top { width: 260px; } 
	  }
  	#branding {
  		> a { padding-left: 0; }
  		.site-slogan { width: calc(100% - 145px);}
  	}
	}

	@media all and (max-width: 639px) {
		//* HEADER TOP
			#header-top {
				.region-header-top { width: 190px;}
			}
			#block-locale-language {
				margin:  6px 5px 0 0;
				font-size: 14px;
			}
			.block-locale { padding: 3px 4px 2px;}
			//* ***** LOGO
				#branding {
					font-size: 20px;
					> a { padding-left: 0;}
					.site-slogan { display: none;}
				}
			//* ***** MENU BASE
				#block-menu-menu-menu-top {
					li {
						margin-right: 5px;
						min-width: 0;
						a {
							padding-left: 8px; padding-right: 8px;
							font-size: 13px;
						}
						&.last { margin-right: 0;}
					}
				}
	}
	@media all and (max-width: 479px) {

	}