@import "_recipes";
@import "_variables";


.field-hiwc-covers-contenido,
.field-name-field-hiwc-covers-contenido {
  //.gl-row-expanded {}
  .gl-row:not(.content-full) .gl-row-expanded-multiple,
  .gl-row-contenedor {
    @extend .container-width;
  }
  .gl-row {
  	&[class*='row-red'],
  	&[class*='row-hover'] {
  		position: relative;
  		// &:before {
  		// 	content: "";
  		// 	position: absolute; left: 0; right: 0; top: 0; bottom: 0;
  		// 	@include rgba-fallback(bg, $corporativoSecundario, 0.8);
  		// 	@include transition(all 0.5s ease-in-out 0s);
  		// }
  		.gl-row-contenedor-unique { position: relative;}
  	}
  	// &[class*='row-hover'] {
  	// 	&:hover:before { @include rgba-fallback(bg, $corporativoSecundario, 0.5);}
  	// }
  	// &.row-hover-black {
  	// 	&:before { @include rgba-fallback(bg, $corporativoSecundario, 0.9);}
  	// 	&:hover:before { @include rgba-fallback(bg, $corporativoSecundario, 0.7);}
  	// }
  	// &.row-red,
  	// &.row-hover-red {
  	// 	&:before { @include rgba-fallback(bg, $corporativoPrincipal, 0.5);}
  	// }
  	// &.row-hover-red {
  	// 	&:hover:before { @include rgba-fallback(bg, $corporativoPrincipal, 0.35);}
  	// }
  	&.row-red,
	&.row-hover-red { filter: url(#multipler-red);}
  	&.row-black,
	&.row-hover-black { filter: url(#multipler-black);}
  }
  .gl-hollow {
  	&.gadget-align-left {
  		text-align: left;
			> .entity-gadget:not(.gadget-destacado) { display: inline-block;}
			.gadget-destacado { margin-left: 13px; margin-right: auto;}
  	}
  	&.gadget-align-right {
  		text-align: right;
			> .entity-gadget:not(.gadget-destacado) { display: inline-block;}
			.gadget-destacado { margin-right: 13px; margin-left: auto;}
  	}
  }

  // RESPONSIVE
  	.gl-row-50-50 {
			@media all and (max-width: 639px) {
				display: block;
				font-size: 0;
				.gl-hollow { display: inline-block;}
				.gl-hollow-50 { width: 100%; max-width: 100%; min-width: 100%;}
				.gl-hollow.gadget-align-left {
					.gadget-destacado { margin-left: auto; margin-right: auto;}
		  	}
		  	.gl-hollow.gadget-align-right {
					.gadget-destacado { margin-right: auto; margin-left: auto;}
		  	}
			}
  	}
		.gl-row-30-70,
		.gl-row-70-30 {
			@media all and (max-width: 799px) {
				display: block;
				font-size: 0;
				.gl-hollow { display: inline-block;}
				.gl-hollow-30 { width: 40%; max-width: 40%; min-width: 40%;}
				.gl-hollow-70 { width: 60%; max-width: 60%; min-width: 60%;}
			}
			@media all and (max-width: 639px) {
				.gl-hollow-30,
				.gl-hollow-70 { width: 100%; max-width: 100%; min-width: 100%;}
			}
		}
		.gl-row-30-40-30 {
			@media all and (max-width: 639px) {
				display: block;
				font-size: 0;
				.gl-hollow { display: inline-block;}
				.gl-hollow-30,
				.gl-hollow-40 { width: 100%; max-width: 100%; min-width: 100%;}
			}
		}
		.gl-row-33-34-33 {
			@media all and (max-width: 899px) {
				display: block;
				font-size: 0;
				.gl-hollow { display: inline-block;}
				.gl-hollow-33,
				.gl-hollow-34 { width: 50%; max-width: 50%; min-width: 50%;}
			}
			@media all and (max-width: 639px) {
				.gl-hollow-33,
				.gl-hollow-34 { width: 100%; max-width: 100%; min-width: 100%;}
			}
		}
		.gl-row-25-25-25-25 {
			@media all and (max-width: 767px) {
				display: block;
				font-size: 0;
				.gl-hollow {
					display: inline-block;
				}
				.gl-hollow-25 { width: 50%; max-width: 50%; min-width: 50%;}
			}
			@media all and (max-width: 479px) {
				.gl-hollow-25 { width: 100%; max-width: 100%; min-width: 100%;}
			}
		}
}