@import "_recipes";
@import "_variables";


//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-title {
		&.view-mode-title_icon {
			position: relative;
	    margin-bottom: 40px;
	    text-align: center;
	    padding: 40px 0 0;
	    @include rgba-fallback(bg, $black, 1); //@include rgba-fallback(bg, $black, 0.9);
	    @include transition(padding-top 0.3s ease-in-out 0s);
	    .field-name-field-hiwc-covers-title {
	      position: relative;
	      display: inline-block;
	      margin: 80px 0 0; padding: 20px 20px 24px;
	      border-top: 1px solid #716f6e;
	      text-align: center; text-transform: uppercase; color: $white; font-size: 30px; line-height: 1.05em;
	    	@include transition(margin-top 0.3s ease-in-out 0s);
	      &:before {
	        position: absolute; top: -80px; left: 0; right: 0;
	        @include icomoon(72px);
	        color: $corporativoPrincipal;
	        @include transition(all 0.3s ease-in-out 0s);
	      }
	    }
      .scrolled & {
      	padding-top: 15px;
      	.field-name-field-hiwc-covers-title { 
      		margin-top: 50px;
      		&:before {
      			top: -55px;
      			font-size: 38px;
      		}
      	}
      }
	    &.product-type-clothes {
	      .field-name-field-hiwc-covers-title:before {content: "\e909";}
	    }
	    // &.product-type-industrial-epis {
	    //   .field-name-field-hiwc-covers-title:before {content: "\e903";}
	    // }
	    // &.product-type-agriculture-maintenance {
	    //   .field-name-field-hiwc-covers-title:before {content: "\e901";}
	    // }
	    &.ico-tax-industrial {
	      .field-name-field-hiwc-covers-title:before {content: "\e903";}
	    }
	    &.ico-tax-agriculture {
	      .field-name-field-hiwc-covers-title:before {content: "\e901";}
	    }
	    &.ico-tax-fire {
	      .field-name-field-hiwc-covers-title:before {content: "\e900";}
	    }
	    &.ico-tax-custom {
	      .field-name-field-hiwc-covers-title:before {content: "\e910";}
	    }
	  }
	}
