//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-detalle {
		display: block;
		margin-top: 30px; margin-bottom: 50px;
		.group-header {
			padding: 14px 26px 12px 100px;
			background-color: $corporativoSecundario;
			> * { display: inline-block; vertical-align: bottom;}
			.field-name-field-hiwc-covers-caption {
				width: 60%;
			}
			.field-name-field-text1-lang { width: 40%;}
		}
		.group-left,
		.group-right { margin-top: 20px; margin-bottom: 20px;}
		.group-left {
			float: left;
			width: 60%;
			> div { margin-left: 100px; margin-right: 26px;}
			+ .group-right { 
				float: right;
				width: 40%;
			}
		}
		.field-name-field-hiwc-covers-caption {
			margin: 0;
			font-size: 18px; color: $white; line-height: 1.05em; font-weight: normal;
			* { margin: 0;}
			strong { font-size: 30px; line-height: 1.05em; font-weight: normal;}
		}
		.field-name-field-text1-lang {
			text-align: right;
			color: #e3b53b; font-size: 18px;
		}
		.field-slideshow-wrapper {
			.field-slideshow-controls {
				a {
					&:before {
						display: block;
						margin-top: -55px;
						width: 28px; height: 110px;
						background-color: $white;
						color: $text; line-height: 110px; font-weight: bold;
					}
				}
				.prev { left: 0;}
				.next { right: 0;}
			}
		}
	}

	// HTML TEMPLATE
	.detail-item {
		display: inline-block; vertical-align: top;
		width: 47.5%;
		margin-bottom: 20px;
		font-size: 16px;
		&:nth-child(2n+1) { margin-right: 1.5%;}
		&:nth-child(2n+2) { margin-left: 1.5%;}
		.detail-title {
			margin-top: 20px; margin-bottom: 20px;
			color: #888888;
		}
		ul {
			padding-left: 0; margin: 0 0 0 18px;
			li { margin: 10px 0;}
		}
	}


//* ********** RESPONSIVE
	@media all and (max-width: 799px) {
		.gadget-detalle {
			.group-header { padding-left: 15px;}
			.group-left > div { margin-left: 15px;}
		}
	}

	@media all and (max-width: 639px) {
		.gadget-detalle {
			.group-left {
				float: none;
				width: 100%;
				+ .group-right {
					float: none;
					 width: 100%;
				}
			}
			.field-name-field-hiwc-covers-files { max-width: 475px; margin: 10px auto 0;}
		}
	}