@import "_recipes";
@import "_variables";


.gadget-separator {
	display: block;
	width: 100%;
	&.separator-space {}
	&.separator-line { border-bottom: 1px solid #e6e6e6;}
	&.separator-shadow {
		position: relative;
		font-size: 0; text-align: center;
		&:before,
		&:after {
			content: "";
			display: inline-block;
			visibility: visible;
			background-image: url(../images/shadow-divider.png);
	    background-repeat: no-repeat;
	    height: 7px;
	    width: 30%;
		}
		&:before  { background-position: left center;}
		&:after { background-position: right center;}
	}
	&.size-large { margin-top: 35px; margin-bottom: 45px;}
	&.size-medium { margin-top: 25px; margin-bottom: 30px;}
	&.size-small { margin-top: 15px; margin-bottom: 15px;}
}