@import "_recipes";
@import "_variables";


.gadget-button {
	margin: 30px 0 40px;
	text-align: center;
	.field-name-field-hiwc-covers-link,
	.field-name-field-hiwc-covers-file {
		a {
			position: relative;
			display: block; margin: 0 auto;
			max-width: 600px;
			padding: 26px 24px 24px;
			background-color: $corporativoPrincipal;
			border: 1px solid $corporativoPrincipal;
			color: $white; text-transform: uppercase; font-weight: bold; text-align: center; font-size: 18px;
			&:before {
				content: '';
				position: absolute; bottom: -6px; right: -1px; left: -1px;
				height: 5px;
				background-color: #cecfca;
				@include transition(all 0.3s ease-in-out 0s);
			}
			&:hover, &:focus {
				&:before { height: 3px; bottom: -4px;}
			}
		}
	}
	.field-name-field-hiwc-covers-file {
		a[type*='pdf'] {
			&:after {
				content: '\e908';
				display: inline-block; vertical-align: middle;
				margin-left: 10px; margin-top: -2px;
				@include icomoon(1.1em); line-height: 1em;
			}
		}
		.file {
			.file-icon,
			.file-size { display: none;}
		}
	}
	//* ********** FEATURES
		// &.button-square {}
		&.button-rounded {
			.field-name-field-hiwc-covers-link,
			.field-name-field-hiwc-covers-file {
				a {
					@include border-top-radius(6px);
					@include border-bottom-radius(6px);
				}
			}
		}
		// &.button-2d {}
		&.button-3d {
			.field-name-field-hiwc-covers-link,
			.field-name-field-hiwc-covers-file {
				a {
					@include linear-gradient($corporativoPrincipal, tint(red, 30%));
					@include linear-gradient(to top, $corporativoPrincipal, tint(red, 30%));
					@include linear-gradient(to top, $corporativoPrincipal, tint(red, 30%) 50%, $fallback: $corporativoPrincipal);
					@include linear-gradient(50deg, $corporativoPrincipal 0%, tint(red, 50%) 50%, tint(red, 35%) 51%, $corporativoPrincipal 100%);
					border: 0 none;
					&:before { display: none;}
					&:hover, &:focus {
						@include linear-gradient(tint(red, 30%), $corporativoPrincipal);
						@include linear-gradient(to top, tint(red, 30%), $corporativoPrincipal);
						@include linear-gradient(to top, tint(red, 30%) 50%, $corporativoPrincipal, $fallback: $corporativoPrincipal);
						@include linear-gradient(50deg, $corporativoPrincipal 0%, tint(red, 35%) 50%, tint(red, 50%) 51%, $corporativoPrincipal 100%);
					}
				}
			}
		}
		&.button-phantom {
			.field-name-field-hiwc-covers-link,
			.field-name-field-hiwc-covers-file {
				a {
					background-color: transparent;
					color: $corporativoPrincipal;
					&:before { display: none;}
				}
			}
		}
		&.size-large {
			.field-name-field-hiwc-covers-link,
			.field-name-field-hiwc-covers-file {
				a {
					max-width: 700px;
					padding: 30px 28px 28px;
					font-size: 22px;
				}
			}
		}
		// &.size-medium {}
		&.size-small {
			.field-name-field-hiwc-covers-link,
			.field-name-field-hiwc-covers-file {
				a {
					max-width: 500px;
					padding: 18px 16px 16px;
					font-size: 14px;
				}
			}
		}
}