@import "bourbon/bourbon";

// * ****** @include icon(fontawesome, R);**** */
  @mixin icon($font-name, $char) {
    &:before {
      content: $char;
      font-family: $font-name;
    }
  }

// * ****** @include icomoon(fa-camera-retro);**** */
 @mixin icomoon($size) {
    font-family: 'icomoon'; font-size: $size;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

//* ***** OPACITY ***** */
  @mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
  }

//*  *****************************************  RGBA SUPPORT */
  // * ****** @include rgba-fallback(color, #123456, 0.5);**** */
  // * ****** @include rgba-fallback(bg, #654321, 0.6);**** */
    @mixin rgba-fallback($type, $color, $percentage) {
      $rgbRED: red($color);
      $rgbGREEN: green($color);
      $rgbBLUE: blue($color);
      @if $type == color {
          color: $color;
          color: rgba($rgbRED, $rgbGREEN, $rgbBLUE, $percentage);
      }
      @if $type == bg {
          background-color: $color;
          background-color: rgba($rgbRED, $rgbGREEN, $rgbBLUE, $percentage);
      }
    }

//* ***** BG-IMAGE SVG SUPPORT ***** */
  @mixin bg-image($image) {
    background-image: url(../images/#{$image}.svg);
    .no-svg & { background-image: url(../images/#{$image}.png);}
  }

// * ****** @include clearfix; **** */
  @mixin clearfix {
    *zoom: 1;
    clear: both;
    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
  }

// * ****** @include invisible; **** */
  @mixin invisible {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
  }

// * ****** @include border-radius; **** */

  @mixin border-radius($radius) {
  -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
      border-radius: $radius;
}