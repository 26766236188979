@import "_recipes";
@import "_variables";


.gadget-contenido {
	display: block; margin-top: 13px; margin-bottom: 13px;
  text-align: center;
  &.view-mode-big {
  	max-width: 1180px; margin-left: auto; margin-right: auto;
  }
  &.view-mode-medium {
  	margin-left: 13px; margin-right: 13px;
  	max-width: 585px; //margin: 0 auto;
  }
  &.view-mode-small {
  	margin-left: 13px; margin-right: 13px;
  	max-width: 370px;
  }
  // .group-link {
  // 	display: table; width: 100%;
  // 	> * { display: table-row;}
  //   .field-name-field-hiwc-covers-title {
  //   	.field-item { display: table-cell; vertical-align: middle;}
  //   }
  // }
  // > .field-name-field-hiwc-covers-title {
  // 	display: table; width: 100%;
  // 	.field-item { display: table-cell; vertical-align: middle;}
  // }
  // .field-name-field-hiwc-covers-title {
  //   background-color: #474543;
  //   margin: 0;
  //   color: $white; font-size: 24px; text-transform: uppercase; line-height: 1.05em;
  //   a { color: inherit;}
  //   .field-item {
		// 	display: table-cell; vertical-align: middle;
  //   	padding: 14px; margin: 0 0;
	 //    height: 2.2em;
	 //  }
  // }
  // //field-name-field-hiwc-covers-image {}
  // .field-name-field-hiwc-covers-html {
		// padding: 14px;
  //   color: $text; font-size: 18px;
  // }
}