@import "_recipes";


//* *************** ADMIN - EDIT/ADD/DELETE CONTENT ***** */
  body[class*='page-admin-admin-content-type'],
  .page-admin-structure-taxonomy, .page-taxonomy-term-translate,
  .page-taxonomy-term-edit,
  .page-node-delete,
  .page-node-translate,
  .page-admin-structure-menu-manage, .page-admin-structure-menu-item,
  .page-admin-structure-entityform-types-manage, .page-entityform {
    .region-content {
      box-sizing: border-box;
      width: 100%; max-width: $maxWidth;
      margin: 150px auto 25px;
      .content { padding: 0 15px;}
    }
  }
  .page-node-delete {
    form.confirmation {
      margin: 45px 0 15px;
    }
  }
  .page-node-translate,
  body[class*='page-admin-admin-content-type'] {
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 2px;
      border-color: grey;
      background-color: $corporativoPrincipal;
      @include border-top-radius(5px);
      @include border-bottom-radius(5px);
      thead { display: table-header-group; vertical-align: middle;}
      tr {
        th, td { padding: 12px 20px;}
        th + th,
        td + td { text-align: center;}
        th { color: $white; font-size: 13px; text-transform: uppercase;}
        td { font-size: 14px; color: grey; line-height: 1.1em;}
        &.odd { background-color: $white;}
      }
    }
  }
  .node-form {
    &[class*='node'] {
      box-sizing: border-box;
      width: 100%; max-width: $maxWidth;
      margin: 150px auto 25px;
      .field-group-fieldset {
        margin: 10px 0 10px; padding: 0; 
        html.js & {
          &.collapsed { height: auto;}
        }
        legend {
          display: block;
          width: 100%; padding: 0;
          background-color: #f5f5f5;
          span { 
            display: block;
            padding: 7px 10px 5px 20px; margin-left: 0px;
            &.summary { padding: 0;}
          }
        }
        .fieldset-wrapper {
          border: 1px solid #f5f5f5;
          padding: 10px;
          font-size: 13px;
        }
        input.form-submit {
          margin: 0 5px;
          background-color: transparent;
          padding: 6px 8px;
          color: $corporativoPrincipal; font-family: $medium; font-size: 13px;
          &:hover, &:active, &:focus {
            background-color: $corporativoPrincipal;
            color: $white;
          }
        }
      }     
      .form-item {
        input.form-text,
        textarea.form-textarea,
        select { 
          padding: 8px 10px 6px;
          font-size: 14px;
        }
        select { width: auto;}
      }
      .filter-guidelines {
        font-size: 12px; line-height: 1.1em;
      }
      .field-type-entityreference,
      .field-type-list-text,
      .field-type-list-boolean {
        .form-type-checkbox,
        .form-type-radio {
          display: inline-block; vertical-align: top;
          width: 30%; margin: 2px 1%;
        }
      }
      .field-type-image {
        table { width: 100%;}
      }
    } 
  }
