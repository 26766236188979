@import "_recipes";
@import "_variables";


//* ************** COMMON ENTITYFORM
  .entityform {
    > div { position: relative;}
    .form-item {
      input.form-text,
      textarea.form-textarea {
        padding: 12px 16px;
        font-size: 14px;
      }
      .compact-form-label {
        left: 16px; top: 12px;
        color: $black;
      }
      select {
        font-size: 14px; color: $black; font-family: $medium; font-weight: bold;
        border-radius: 0;
        -webkit-appearance: none;
      }
      textarea.form-textarea { min-height: 90px;}
    }
    .form-item-field-bool1-und-und {
      a {text-decoration: underline;}
    }
    .form-actions {
      margin: 0;
      width: 23.2%;
      input.form-submit {
        display: block;
        box-sizing: border-box;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }

//* *************** ENTITYFORM HELP
  .entitytype-help-form {
    > div {
      display: table; width: 100%;
      .pre-intructions { display: table-header-group;}
    }
    .pre-intructions {
      text-align: center;
      h3 {
        display: inline-block;
        padding: 20px 40px 5px; margin: 0 0 15px;
        text-transform: uppercase; font-size: 24px;
      }
    }
    .ds-form {
      display: table; width: 100%;
      &:after { content: "";}
      .group-left { width: 51.2%;}
      .group-middle { width: 25.6%;}
      .group-right { width: 23.2%;}
      .form-wrapper {
        .form-item {
          margin-top: 5px; margin-bottom: 0;
          &.form-item-field-bool1-und-und { margin-left: 20px; }
          &:not(.form-item-field-bool1-und-und) { margin-right: 20px; }
        }
      }
      .group-left {
        font-size: 0;
        .form-wrapper {
          display: inline-block; vertical-align: middle;
          width: 50%;
          font-size: 14px;
        }
      }
    }
    .form-actions {
      position: absolute; bottom: 0px; right: 0px;
    }
  }

//* ************** CONTACT PRODUCTO DETAIL
  .field-name-form-help {
    position: fixed; bottom: 0px; left: 0; right: 0;
    margin: 0;
    height: 0;
    @include transition(height 0.5s ease-in-out 0s);
    background-color: $corporativoSecundario;
    color: $white;
    &.turn-to-absolute { position: absolute; bottom: -120px;}
    &.hide-form { height: 135px;}
    a { color: inherit;}
    .ds-form { padding: 0;}
    > .field-items {
      @extend .container-width;
      > .field-item {
        position: relative;
        padding: 20px 40px 20px;
      }
    }
    .pre-intructions {
      position: absolute; top: 0px; left: 0; right: 0;
      @include transition(top 0.5s ease-in-out 0s);
      cursor: pointer;
      .scrolled & { top: -65px;}
      h3 {
        background-color: $corporativoSecundario;
        color: $white;
      }
    }
    &:not(.hide-form) {
    	.pre-intructions h3 { background-color: $corporativoPrincipal;}
    }
    &.hide-form {
      .pre-intructions { top: -65px;}
    }
  }


//* ************** HTML TEMPLATE
  #pmesa-location {
    position: relative;
    margin: 35px 0 25px; padding: 26px 0 0px;
    text-align: center;
    &:before {
      content: "";
      position: absolute; top: 0; left: 0; right: 0;
      max-width: 200px; margin: 0 auto;
      border-top: 1px solid $corporativoSecundario;
    }

  }


//* ************** RESPONSIVE ***** */
	@media all and (max-width: 899px) {
		.field-name-form-help {
			&.hide-form {
				height: 175px;
				.pre-intructions { top: -59px;}
			}
			.pre-intructions {
				.scrolled & { top: -59px;}
				h3 { padding: 16px 20px 10px;}
			}
			.ds-form {
				.group-left { width: 55%;}
				.group-middle, .group-right { width: 45%;}
				.form-wrapper {
					.form-item {
						&:not(.form-item-field-bool1-und-und) { margin-right: 10px;}
						&.form-type-textarea { margin-right: 0;}
						&.field-tye-list-boolean { margin-top: 15px; margin-bottom: 10px;}
						&.form-item-field-bool1-und-und { margin-left: 0; margin-top: 10px;}
					}
				}
			}
			.form-actions { margin-bottom: -5px;}
		}
	}
	@media all and (max-width: 767px) {
		.field-name-form-help {
			&.hide-form { height: 260px;}
			> .field-items {
				> .field-item { padding: 14px 10px 14px;}
			}
			.ds-form {
				.group-left {
					width: 45%;
					.form-wrapper { width: 100%;}
				}
				.group-middle, .group-right { width: 55%;}
				.form-wrapper {
				}
				.form-item {
					textarea.form-textarea { min-height: 100px;}
				}
			}
			.form-actions {
				bottom: 0;
				width: 55%;
			}
		}
	}
	@media all and (max-width: 479px) {
		.field-name-form-help {
			.pre-intructions {
				.scrolled & { top: -60px;}
				h3 {
					min-width: 200px; margin-left: auto; margin-right: auto;
					font-size: 20px
				}
			}
			&.hide-form { height: 415px;}
			.ds-form {
				.group-left {	width: 100%;}
				.group-middle, .group-right { width: 100%;}
				.form-wrapper {
					.form-item.form-item:not(.form-item-field-bool1-und-und) { margin-right: 0px;}
				}
			}
			.form-actions {
				position: relative;
				width: 100%;
			}
		}
	}