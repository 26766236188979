@import "_recipes";
@import "_variables";


.gadget-icons {
	.field-name-field-hiwc-covers-linkicon { margin-right: 13px; margin-left: 13px;}
	.item-list--linkicon { margin-top: 30px; margin-bottom: 50px;}
	.item-list--linkicon ul.linkicon li {
		display: block;
		padding-top: 18px; margin: 10px auto;
	}
	.linkicon {
		display: block;
		border-top: 1px solid transparent;
		color: $corporativoSecundario; text-align: center;
		a {
			display: block;
			color: inherit;
		}
		&:hover { color: $corporativoPrincipal;}
		.icon {
			&:before {
				display: block; margin: 0 auto;
 				height: auto; width: auto;
				font-size: 47px; line-height: 1em;
			}
		}
		.linkicon__text {
			display: block;
			padding: 0px 10px 10px; margin: 16px 0 0px;
			font-size: 20px; line-height: 1.05em;
		}
	}
	//* ***** POSITION
		&.icon-top {
			.linkicon {
				.linkicon__text {
					display: block;
					padding: 20px 10px;
					border-top: 1px solid $corporativoSecundario;
					border-bottom: 1px solid $corporativoSecundario;
					text-transform: uppercase;
				}
			}
			&:hover {
				.linkicon {
					border-top-color: $corporativoPrincipal;
					.linkicon__text {
						border-color: $corporativoPrincipal;
						border-top-color: transparent;
					}
				}
			}
		}
		&.icon-bottom {
			li a {
				display: table; width: 100%;
				.icon { display: table-footer-group;}
			}
		}
		&.icon-left {
			.linkicon li a {
				display: block;
				.icon,
				.linkicon__text { display: inline-block; vertical-align: middle;}
				.linkicon__text {
					margin: 0 0 0 10px; padding: 0;}
			}
		}
	//* ***** SIZE
		&.size-large {
			.linkicon {
				.icon:before { font-size: 47px; height: 47px;}
				.linkicon__text {	font-size: 20px;}
			}
		}
		&.size-medium {
			.linkicon {
				.icon:before { font-size: 34px;}
				.linkicon__text {	font-size: 18px;}
			}
		}
		&.size-small {
			.linkicon {
				.icon:before { font-size: 24px;}
				.linkicon__text {	font-size: 16px;}
			}
		}
}

.gadget-icons {
	.linkicon {
		@include transition(margin 0.2s ease-in-out 0s);
		li {
			position: relative;
		}
		.linkicon__text {
			padding-bottom: 18px; padding-top: 18px;
			border-bottom: 1px solid $corporativoSecundario;
			@include transition(padding 0.2s ease-in-out 0s);
			&:before {
				content: "";
				position: absolute;	top: 90px; left: 0; right: 0;
				display: block;
				border-bottom: 1px solid $corporativoSecundario;
				@include transition(top 0.2s ease-in-out 0s);
			}
		}
		&:hover {
			margin-bottom: -8px;
			.linkicon__text {
				padding-bottom: 32px; padding-top: 12px;
				border-color: $corporativoPrincipal;
				&:before {
					position: absolute;top: -10px;
					border-color: $corporativoPrincipal;
				}
			}
		}
	}
}