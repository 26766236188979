@import "_recipes";
@import "_variables";

//* *************** LAYOUT ***** */
	body,
	.gadget-texto	.field-name-field-hiwc-covers-html {
	  margin: 0;
	  font-family: $medium; color: $text; font-size: 16px;
	  line-height: 1.28em;
	}
	.container-width {
    box-sizing: border-box;
	  width: 100%; max-width: $maxWidth;
	  margin: 0 auto; padding: 0 13px;
	}

	iframe { max-width: 100%;}


//* ************** LAYOUT ***** */
  .title-h1 {
    h1 { margin: 34px 0 40px 200px;}
  }
  h1 { font-size: 30px; line-height: 1.1em;}
  .ds-2col-stacked-fluid-custom {
    .group-header {
      max-width: 100%;
      margin: 15px 0 35px; padding: 0;
    }
    > .container-width {
      > .group-left { width: 67.75%;}
      > .group-right {
        width: 32.25%;
        > div { margin-left: 35px;}
      }
    }
  }
  // .ds-1col-stacked-custom {

  // }

  #block-crumbs-breadcrumb { display: none;}

//* ************** TABLE - RESPONSIVE ****** */
  table {
    width: 100%;
    margin: 20px 0;
    line-height: 1.1em;
    th {
      padding: 4px;
      border: 0 none;
    }
    td { padding: 2px 4px;}
    th + th, td + td { text-align: center;}
    th {
      vertical-align: bottom;
      font-size: 13px; text-transform: uppercase; color: $corporativoPrincipal;
    }
    thead {
      border-bottom: 1px solid #efeeee;
      tr + tr {
        th {font-size: 11px; text-transform: none; color: $text;}
      }
    }
    tbody { border: 0 none;}
    td {
      font-size: 14px;
      border-bottom: 1px solid #efeeee;
    }
  }
  .responsive-table {
    &.col6 {
      th {
        text-align: center;
        @media (max-width: 479px) { display: none;}
      }
      tr {
        @media (max-width: 479px) {
          border: 2px solid $corporativoPrincipal;
          &:nth-child(odd) {
            background-color: #efeeee;
            td { border-color: $white;}
          }
        }
      }
      td {
        text-align: center;
        @media (max-width: 479px) {
          display: block;
          width: auto !important;
          padding: 4px 10px;
          text-align: left;
          &.empty { display: none;}
        }
        &:before {
          content: attr(data-head)": ";
          display: inline-block;
          width: 200px;
          font-weight: bold;
          @media (min-width: 480px) { display: none;}
        }
      }
    }
    &.col7, &.col8 {
      th {
        text-align: center;
        @media (max-width: 639px) { display: none;}
      }
      tr {
        @media (max-width: 639px) {
          border: 2px solid $corporativoPrincipal;
          &:nth-child(odd) {
            background-color: #efeeee;
            td { border-color: $white;}
          }
        }
      }
      td {
        text-align: center;
        @media (max-width: 639px) {
          display: block;
          width: auto !important;
          padding: 4px 10px;
          text-align: left;
          &.empty { display: none;}
        }
        &:before {
          content: attr(data-head)": ";
          display: inline-block;
          width: 200px;
          font-weight: bold;
          @media (min-width: 640px) { display: none;}
        }
      }
    }
    &.col10, &.col11 {
      th {
        text-align: center;
        @media (max-width: 679px) { display: none;}
      }
      tr {
        @media (max-width: 679px) {
          border: 2px solid $corporativoPrincipal;
          &:nth-child(odd) {
            background-color: #efeeee;
            td { border-color: $white;}
          }
        }
      }
      td {
        text-align: center;
        @media (max-width: 679px) {
          display: block;
          width: auto !important;
          padding: 4px 10px;
          text-align: left;
          &.empty { display: none;}
        }
        &:before {
          content: attr(data-head)": ";
          display: inline-block;
          width: 200px;
          font-weight: bold;
          @media (min-width: 680px) { display: none;}
        }
      }
    }
  }

//* ************** VIEW ***** */
	//* PAGER
		.item-list .pager {
      li {
        padding: 0; margin: 0 5px;
        &.pager-current,
        &.pager-item a {
          display: inline-block;
          width: 30px; height: 30px;
          padding: 0; margin: 0;
          background-color: $grey;
          @include border-radius(30px);
          line-height: 30px;
          font-size: 12px; color: $white; font-family: $medium; font-weight: bold;
          &:hover {
            background-color: $corporativoPrincipal;
            color: $white;
          }
        }
        &.pager-previous, &.pager-next,
        &.pager-first, &.pager-last {
        	margin-left: 0; margin-right: 0;
          a {
          	padding-left: 0; padding-right: 0;
          	width: 28px;
						font-size: 0;
          	&:before {
          		vertical-align: middle;
          		@include icomoon(12px);
          		line-height: 20px; font-weight: bold;
          	}
          	&:hover {
          		color: $corporativoPrincipal;
							&:before { font-size: 14px;}
          	}
        	}
        }
        &.pager-previous a:before { content: "\e905";}
        &.pager-next a:before { content: "\e906";}
        &.pager-first a:before { content: "\e905\e905";}
        &.pager-last a:before { content: "\e906\e906";}
        &.pager-current {
          margin: 0 5px;
          background-color: $corporativoPrincipal;
        }
      }
    }

//* ************** EU COOKIES ***** */
  #sliding-popup {
    &.sliding-popup-bottom,
    &.sliding-popup-top {
      @include rgba-fallback(bg, $corporativoPrincipal, 0.9);
      padding: 20px 0px 10px;
    }
    .popup-content {
      max-width: 1040px;
      margin: 0px auto; padding: 0 10px;
      a { color: #fff; text-decoration: underline;}
      #popup-buttons {
        button {
          @include border-top-radius(0px);
          @include border-bottom-radius(0px);
          box-shadow: none;
          padding: 4px 10px;
          background: 0 none;
          background-color: transparent;
          border: 1px solid #FFFFFF;
          color: $white; font-weight: normal; text-shadow: none;
          @include transition(all 0.5s ease-in-out 0s);
          &:hover {
            background: 0 none;
            @include rgba-fallback(bg, $white, 0.4);
          }
        }
        .agree-button {}
        .find-more-button {}
      }
    }
  }

//* ************** SHARETHIS ***** */
	// .sharethis-wrapper {
	// 	span {
	// 		display: inline-block;
	// 		margin: 5px;
	// 		&:before {
	// 			@include icomoon(20px);
	// 			content: "\e900";
	// 		}
	// 		&:hover { color: $corporativoSecundario;}
	// 	}
	// 	.st_facebook_custom:before { content: "\e900";}
	// 	.st_pinterest_custom:before { content: "\ead1";}
	// 	.st_twitter_custom:before { content: "\ea96";}
	// 	.st_whatsapp_custom:before { content: "\e903";}
	// 	.st_googleplus_custom:before { content: "\e92f";}
	// }


//* *************** RESPONSIVE ***** */
  @media all and (max-width: 1023px) {
    //* ********** HEADER *** */
      .title-h1 h1 { margin-top: 20px; margin-left: 140px;}
  }
  @media all and (max-width: 639px) {
    //* ********** HEADER *** */
      .title-h1 h1 { margin-top: 55px; margin-left: 0px;}
  }
