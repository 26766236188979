@import "_recipes";
@import "_variables";


//* ************** BLOG ***** */
  [class*='view-blog'] {  
    .views-row {
      .node {
        border-bottom: 1px solid $text;
        margin-bottom: 30px; padding-bottom: 30px;
      }
    }    
  }

  #block-menu-menu-categorias-blog,
  .field-name-blog-categories {
    > h2 { display: none;}
  }

  #block-menu-menu-categorias-blog .content,
  .field-name-blog-categories .field-item {
    width: 100%; max-width: $maxWidth;
    margin: 0 auto;
    .menu { 
      margin: 0; padding: 0 10px;
      border: 0 none;
      text-align: center; font-size: 0;
      li {
        display: inline-block; vertical-align: bottom;
        max-width: 25%;
        margin: 0 0 -1px -1px; padding: 0;
        border: 1px solid $text;
        &.active-trail { 
          margin-top: -12px;
          border-bottom: 0 none;
        }
        a { 
          display: block;
          padding: 20px 70px;
          background-color: #fafcff;
          font-family: $medium; font-size: 20px; color: $text; line-height: 1.1em; font-weight: bold;
          &.active-trail {
            margin: 0; padding-top: 32px;
            background-color: $white;
            border-bottom: 0 none;
            color: $corporativoPrincipal; //text-transform: uppercase;
          }
          &:hover, &:focus {
            margin: 0;
            border-bottom: 0 none;
            color: $corporativoPrincipal;
          }
        }
      }
    }
  }
  .block-tagclouds,
  .field-name-blog-tags-cloud {
    > h2,
    .more-link { display: none;}
  }
  .block-tagclouds .content,
  .field-name-blog-tags-cloud .field-item {
    padding: 65px 30px;
    @include border-radius(4px);
    background-color: #ecf2f6;
    a { 
      color: $text;
      &.active, &:hover, &:focus { color: $corporativoPrincipal;}
    }
  }

  .node-post-blog {
    .field-name-field-img1-lang {
      img { margin-right: 20px;}
    }
    .field-name-field-date1-und {
      margin: 6px 0 10px;
      font-size: 14px; font-family: $medium;
      & > * { display: inline-block; vertical-align: middle;}
      //.field-label { letter-spacing: 4px;}
      .date-display-single { 
        font-family: $medium; font-weight: bold; //letter-spacing: 1px; 
      }
    }
    &.view-mode-full {
      .field-name-field-img1-lang { 
        float: left;
        img { margin-bottom: 10px;}
      }
      .field-name-volver {
        margin: 10px 0 30px;
        a {
          padding: 0;
          background: 0 none;
          color: $corporativoPrincipal; text-decoration: underline; font-family: $medium; font-weight: bold;
          &:hover { background-color: transparent;}
        }
      }
      .field-name-field-tags-blog {
        margin: 25px 0 20px;
        padding: 16px 24px;
        @include border-radius(4px);
        background-color: #ecf2f6;
        .field-item {
          display: inline-block; vertical-align: bottom;
          &:not(.last) { margin-right: 5px;}
        }
        a { 
          color: $text;
          &.active, &:hover, &:focus { color: $corporativoPrincipal;}
        }
      }
      //* *** SHARE THIS * */
        .group-share {
          margin: 20px 0 10px;
          text-align: right;
          > * { display: inline-block; vertical-align: middle;}
          h3 { margin: 0;}          
        }
      //* *** COMMENTS * */
        // #comments {
        //   margin: 30px auto 0;
        //   .ds-2col {
        //     .group-left { width: 20%;}
        //     .group-right { width: 75%;}
        //     .ds-form {  
        //       margin: 0 15px;      
        //       .group-left { width: 20%;}
        //       .group-right { 
        //         width: 80%;
        //         > div { margin-left: 15px;}
        //       }
        //     }
        //   }
        //   .title {
        //     margin: 0 0 0px; padding: 0.3em 0.8em 0.2em;
        //     background: none $corporativoSecundario;
        //     color: $white; font-size: 18px; font-family: $medium; text-transform: uppercase; font-weight: bold;
        //     &.comment-form { 
        //       margin-bottom: 8px;
        //       font-size: 0.9em;
        //     }
        //   }
        //   .comment { 
        //     margin: 0 0; padding: 0 15px 0;
        //     //border-bottom: 1px solid $corporativoSecundario;
        //     .group-left { padding-top: 18px;}
        //     .group-right {
        //       padding: 18px 0 15px 15px;
        //       border-left: 1px solid $corporativoSecundario;
        //     }
        //     .username { 
        //       display: block;
        //       margin: 0 0 8px;
        //       font-family: $medium; font-weight: bold;
        //     }
        //     &.comment-preview,
        //     &.comment-unpublished { 
        //       @include box-sizing(border-box);    
        //       padding: 0 15px;
        //       border: 0 none; 
        //       @include rgba-fallback(bg, $corporativoSecundario, 0.08);   
        //     }
        //   }
        //   .form-item {
        //     label { 
        //       margin: 0 0 0.5em;
        //       &.compact-form-label { top: 5px; left: 10px;}
        //     }
        //     .form-text,
        //     .form-textarea {
        //       @include box-sizing(border-box);
        //       width: 100%;
        //       padding: 6px 10px 4px;
        //       @include border-radius(2px);
        //       font-size: 15px; font-family: $medium;
        //     }
        //   }
        //   .form-submit { margin-left: 1em;}
        // } 
    }
    &[class*='view-mode-teaser'] {
      font-size: 14px;
      & > .group-left { 
        width: 28%;
        & + .group-right { width: 72%;}
      }
      .field-name-field-img1-lang { margin-right: 20px;}
      .field-name-field-date1-und { 
        margin-bottom: 10px;
        font-size: 12px;
      }
      .field-name-title {
        margin-bottom: 5px;
        border: 0 none;
        font-size: 26px; color: $corporativoPrincipal; line-height: 1.1em;
        a {
          display: block;
          max-height: 2.2em; overflow: hidden;
          color: inherit;
          text-overflow: ellipsis;
        }
      }
      .field-name-field-longtext1-teaser-lang { 
        margin-bottom: 10px;
        font-size: 16px;
      }
      .field-name-node-link {
        text-align: left;
        a {
          padding: 0;
          background: 0 none;
          color: $corporativoPrincipal; text-decoration: underline; font-family: $medium; font-weight: bold;
          &:hover { background-color: transparent;}
        }
      }
      &.node-sticky {
        .view-blog-ebrosa.view-display-id-page_1 .views-row-first & {
          padding: 6px; margin-bottom: 45px;
          background-color: $corporativoPrincipal;
          border-bottom: 0 none;
          color: #D6D6D6; 
          .field-name-title,
          .field-name-node-link a { color: $white;}
        }
      }
    }
  }

//* *************** RESPONSIVE
  @media all and (max-width: 1023px) {
    .node-post-blog{
      &[class*='view-mode-teaser'] {
        > .group-left {
          width: 32%; 
          + .group-right { width: 68%;} 
        }
      }
    }
  }

@media all and (max-width: 799px) {
  .node-post-blog{
    &[class*='view-mode-teaser'] {
      > .group-left {
        width: 38%; 
        + .group-right { width: 62%;} 
      }
    }
  }
}

@media all and (max-width: 639px) {
  .node-post-blog{
    &[class*='view-mode-teaser'] {
      > .group-left {
        @include invisible;
        + .group-right { width: 100%;} 
      }
    }
  }
}  